import { post, get } from '@http/axios'
export const getApiList = () => post('/api/common/public/routelist')
export const login = (params) => post('/login', params)

export const getOssToken = (params) => get('/api/ali-oss/security', { params })

// 获取医生信息
export const getDoctorInfo = () => get('api/doctor-platform/doctor/info')

// 获取登录验证码
export const sendSMS = (params) => post('api/doctor-platform/sendSMS', params)
